import * as React from 'react';
import PropTypes from "prop-types";
import {ArrowForward} from '../../img/arrowForward';
import {ArrowBack} from '../../img/arrowBack';
import {CloseIcon} from '../../img/closeIcon';
import {printTags, cloudinaryUrlFromLink} from "../../../common/contentAux";
import styled from "styled-components";
import * as styles from './lightbox.module.scss';

const Overlay = styled.div`
    position: absolute;
    bottom: 0;
    padding: 6px;
    background-color: rgba(0, 0, 0, 0.5);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
`;

export default class Lightbox extends React.Component {
    constructor(props) {
        super(props);
        this.backdrop = React.createRef();

        this.state = {
            overlayW: 0,
        }

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.xClick = this.xClick.bind(this);
        this.showOverlay = this.showOverlay.bind(this);
        this.hideOverlay = this.hideOverlay.bind(this);
        this.backFunction = this.backFunction.bind(this);
        this.forwardFunction = this.forwardFunction.bind(this);
    }

    handleClickOutside = evt => {
        if (evt.target === this.backdrop.current) {
            this.props.closeLightbox();

        }
    }

    xClick() {
        this.props.closeLightbox();
    }

    escFunction(e) {
        if (e.keyCode === 27) {
            this.props.closeLightbox();
        }
    }

    hideOverlay() {
        this.setState({showOverlay: false});
    }

    showOverlay() {
        this.setState({showOverlay: true});
    }

    forwardFunction(e) {
        if (e.keyCode === 39) {
            this.props.getNextImg();
        }
    }

    backFunction(e) {
        if (e.keyCode === 37) {
            this.props.getPrevImg();
        }
    }



    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
        document.addEventListener("keydown", this.backFunction, false);
        document.addEventListener("keydown", this.forwardFunction, false);
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
        document.removeEventListener("keydown", this.backFunction, false);
        document.removeEventListener("keydown", this.forwardFunction, false);

    }

    render() {
        const currImg = this.props.images[this.props.currentImageI];

        return (
        
                    
           <div onClick={this.handleClickOutside} ref={this.backdrop} className={styles.container}>
                <ArrowBack className={styles.arrow} arrowFill="#ffffff" onClick={this.props.getPrevImg}/>
                <div className={styles.innerContainer}>
                    <img 
                        src={cloudinaryUrlFromLink(currImg.src, 'iw')} 
                        alt={currImg.tags} 
                        onMouseEnter={this.showOverlay} 
                        onMouseLeave={this.hideOverlay}
                    />
                    <Overlay onMouseEnter={this.showOverlay}>
                        <p className={styles.tagText}>{printTags(currImg.tags)}</p>
                    </Overlay>
                </div>
                <ArrowForward className={styles.arrow} arrowFill="#ffffff" onClick={this.props.getNextImg}/>
                <CloseIcon className={styles.closeIcon} onClick={this.xClick}/>
                
           </div>                 
            
        );
    }
}

Lightbox.propTypes = {
  images: PropTypes.array.isRequired,
  currentImageI: PropTypes.number.isRequired,
  closeLightbox: PropTypes.func.isRequired,
  getNextImg: PropTypes.func.isRequired,
  getPrevImg: PropTypes.func.isRequired,   
};
