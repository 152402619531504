import React from "react";


export const ArrowForward = ({
  className = "",
  onClick = undefined,
  arrowFill = "#000000"}) =>


<svg xmlns="http://www.w3.org/2000/svg" onClick={onClick}
  className={className}    
  viewBox="0 0 24 24">
  <path style={{fill:arrowFill, fillOpacity:0.6}} d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
  />
</svg>;

