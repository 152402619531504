import * as React from 'react';
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import {CheckBox} from "./checkBox";
import Dropdown from "../../../common/dropdown";
import * as styles from './filter.module.scss';

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.filterBox = React.createRef();



        this.kepekToggle = this.kepekToggle.bind(this);
        this.videokToggle = this.videokToggle.bind(this);


    }

   
    kepekToggle() {
        this.props.toggleCheck(0);
    }

    videokToggle() {
        this.props.toggleCheck(1);
    }
    
   


    render() {
        return (

            <div className={styles.filterRow}>
                <div className={styles.left}>
                    <p className={styles.title}>Szűrő:</p>
                    <div className={styles.kepekBox}>
                        <CheckBox 
                            property="képek" 
                            checked={this.props.kepekChecked}
                            toggleCheck={this.kepekToggle}
                        />
                    </div>
                    <div className={styles.videokBox}>

                        <CheckBox 
                            property="videók" 
                            checked={this.props.videokChecked}
                            toggleCheck={this.videokToggle}

                        />
                    </div>
                </div>

                <div className={styles.right}>
                    <p className={styles.instr}>Kategória:</p>
                    <div className={styles.dropdown}>
                        <Dropdown items={this.props.tags} updateCategory={this.props.updateCategory}/>
                    </div>
                </div>



            </div>


            
        );
    }
}

export default props => (
    <StaticQuery
      query={graphql`
        query allTagsQuery {
            tags: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(tags)/"}}) {
                edges {
                    node {
                        frontmatter {
                            tag_name
                        }
                    }
                }
            }
        }
      `}
      render={({ tags }) => <Filter tags={tags.edges.map(edge => edge.node.frontmatter.tag_name)} {...props} />}
    />
  );

Filter.propTypes = {
    kepekChecked: PropTypes.bool.isRequired,
    videokChecked: PropTypes.bool.isRequired,
    toggleCheck: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    
};
