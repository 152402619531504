import * as React from 'react';
import PropTypes from "prop-types";
import * as styles from './checkBox.module.scss';
import {CheckedBox} from "../../img/checkedBox";
import {UncheckedBox} from "../../img/uncheckedBox";



export class CheckBox extends React.Component {
 

      

    render() {
        return (
                 
            <div className={styles.container}>
                {this.props.checked ? 
                    <CheckedBox className={styles.box} onClick={this.props.toggleCheck}/> : 
                    <UncheckedBox className={styles.box} onClick={this.props.toggleCheck}/>}
                <p className={styles.property}>{this.props.property}</p>
                
            </div>                   
            
        );
    }
}

CheckBox.propTypes = {
    checked: PropTypes.bool.isRequired,
    property: PropTypes.string.isRequired,
    toggleCheck: PropTypes.func.isRequired,

    
};
