import React, { useState, useEffect } from 'react';
import { useQueryParam, StringParam } from "use-query-params";
import * as styles from './dropdown.module.scss';
import {ExpandArrow} from '../content/img/expandArrow';
import {CollapseArrow} from '../content/img/collapseArrow';
import onClickOutside from "react-onclickoutside";

function Dropdown({items, updateCategory}) {
    const [categoryQueryParam, setCategoryQueryParam] = useQueryParam("kategoria", StringParam);
    const validatedCategoryQueryParam = items.find(tag => tag === categoryQueryParam) || 'összes';

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(validatedCategoryQueryParam);

    useEffect(() => {
        setSelected(validatedCategoryQueryParam);
        updateCategory(validatedCategoryQueryParam);
    }, []);

    Dropdown.handleClickOutside = () => setOpen(false);

    const toggleDropdown = () => setOpen(!open);

    const updateSelected = (newCategory) => {
        setSelected(newCategory);
        updateCategory(newCategory);
        toggleDropdown();
        setCategoryQueryParam(newCategory, 'replace');
    }

    const getSelectedClass = () => open ? `${styles.selected} ${styles.open}` : styles.selected;

    let dropdownItems = [...items];
    if (selected !== "összes") {
        dropdownItems.splice(0, 0, "összes");
    } 
    const displayItems = dropdownItems
                    .filter(item => item !== selected)
                    .map((item, i) => <p className={styles.listItem} key={i} onClick={(e) => updateSelected(e.currentTarget.textContent)}>{item}</p>
            );

    return (
                
        <div className={styles.box}>
            <div className={styles.top} onClick={toggleDropdown}>
                <p className={getSelectedClass()}>{selected}</p>
                {open ? 
                    <CollapseArrow className={styles.arrow} /> :
                    <ExpandArrow className={styles.arrow} />
                }
            </div>
            {open && displayItems}

        </div>
        
    );
}

const clickOutsideConfig = {
    handleClickOutside: () => Dropdown.handleClickOutside
  };

export default onClickOutside(Dropdown, clickOutsideConfig);
