import React from "react";


export const UncheckedBox = ({
  className = "",
  onClick = "",
  iconFill = "#3c3856"}) =>


<svg xmlns="http://www.w3.org/2000/svg" onClick={onClick}
  className={className}    
  viewBox="0 0 24 24">
  <path style={{fill:iconFill, fillOpacity:1}} 
	d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1zm1-16H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
  />
</svg>;