import React, { useState, useEffect } from 'react';
import {Video} from "./video";
import Filter from "./filter";
import Lightbox from "./lightbox";
import * as styles from './gallery.module.scss';
import { Image } from './image';

function isImage(medium) {
    return !Boolean(medium.videoID);
}

function displayedImages(media) {
    return media.filter(m => isImage(m));
}

function ofRightType(medium, mediaTypes) {
    if (isImage(medium)) {
        return mediaTypes[0];
    } else {
        return mediaTypes[1];
    }
}

function filterMedia(arr, category, mediaTypes) {
    if (category === "összes" && mediaTypes === [true, true]) {
        return arr;
    }

    return arr.filter(m => 
        ofRightType(m, mediaTypes) && 
        (category === "összes" || m.tags.includes(category))
    );
}



export default function Gallery({allMedia, filter = true, permanentTag = false}) {
    const [inFocus, setInFocus] = useState(-1);
    const [inLightbox, setInLightbox] = useState(-1);
    const [checked, setChecked] = useState([true, true]);
    const [category, setCategory] = useState('összes');
    const [displayedMedia, setDisplayedMedia] = useState(allMedia);

    const toggleCheck = (checkedIndex) => {
        let newChecked = [...checked];
        newChecked[checkedIndex] = !checked[checkedIndex];
        setChecked(newChecked);
        setDisplayedMedia(filterMedia(allMedia, category, newChecked));
    }

    const updateCategory = (newCategory) => {
        setCategory(newCategory);
        setDisplayedMedia(filterMedia(allMedia, newCategory, checked));
    }

    const blurImg = () => setInFocus(-1);

    const openImgInLightbox = (img) => setInLightbox(displayedImages(displayedMedia).indexOf(img));

    const closeLightbox = () => setInLightbox(-1);

    const getNextImg = () => {
        const next = (inLightbox === displayedImages(displayedMedia).length - 1) ? 0 : inLightbox + 1;
        setInLightbox(next);
    }

    const getPrevImg = () => {
        const prev = (inLightbox === 0) ? displayedImages(displayedMedia).length - 1 : inLightbox - 1;
        setInLightbox(prev);
    }

    return (
        <div className={styles.outerContainer}>
            {filter && <Filter 
                kepekChecked={checked[0]}
                videokChecked={checked[1]}
                toggleCheck={toggleCheck}
                updateCategory={updateCategory}
            />}
            <div className={styles.container}>   
            {displayedMedia.length > 0
                ? <div className={styles.imageGrid}>
                    {displayedMedia.map((m, i) => isImage(m)
                        ? <Image 
                            key={i} 
                            index={i} 
                            img={m}
                            inFocus={inFocus === i} 
                            focusImg={setInFocus} 
                            blurImg={blurImg} 
                            openImgInLightbox={() => openImgInLightbox(m)}
                            permanentTag={permanentTag}
                    />
                    : <Video key={i} video={m} />
                    )}
                </div>
                : <div className={styles.nincs}>
                    <p className={styles.nincsText}>Nincs találat</p>
                </div>}
                            

                {inLightbox > -1 && 
                    <Lightbox 
                        images={displayedImages(displayedMedia)} 
                        currentImageI={inLightbox}
                        closeLightbox={closeLightbox}
                        getNextImg={getNextImg}
                        getPrevImg={getPrevImg}
                />}

            </div>
        </div>           
    );

}
