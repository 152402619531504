import React from "react";
import {ContentPage} from "../contentPage";
import { useStaticQuery, graphql } from "gatsby";
import Gallery from "./gallery";
import {shuffleArray} from "../../../common/contentAux";

export default function GaleriaPage() {
	const data = useStaticQuery(graphql`
		query GalleryQuery {
			albumsData: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(albums)/"}}) {
				edges {
					node {
						frontmatter {
							image_groups {
								images
							}
							videos {
								video_id
							}
							tag_list
						}
					}
				}
			}
		}	
	`);
	
	const allMedia = data.albumsData.edges.flatMap(album => {
		const image_groups = album.node.frontmatter.image_groups || [];
		const videos = album.node.frontmatter.videos || [];
		const tag_list = album.node.frontmatter.tag_list || [];
		const IMAGES = image_groups.flatMap(group => 
			group.images.map(link => ({
				src: link, 
				tags: tag_list, 
			}))
		);
		const VIDEOS = videos.map(video => ({videoID: video.video_id, tags: tag_list}));
		return [...IMAGES, ...VIDEOS];
	});
	shuffleArray(allMedia);
	
	return (
		<ContentPage activePage={2}>
			<Gallery allMedia={allMedia}/>
		</ContentPage>
		);
	}