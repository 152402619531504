import * as React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import {printTags, cloudinaryUrlFromLink} from "../../../common/contentAux";
import * as styles from './image.module.scss';
import * as galleryStyles from './gallery.module.scss';


const Overlay = styled.div`
        height: 100%;
        width: 100%;
        border-radius: inherit;
        cursor: pointer;
        display: flex;
        align-items: flex-end;
        position: absolute;
        top: 0;
`;

export class Image extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        this.props.focusImg(this.props.index);
    }

    handleMouseLeave() {
        this.props.blurImg();
    }
 
    render() {

        const firstTag = this.props.img.tags && this.props.img.tags.length > 0 && this.props.img.tags[0];
        const imgTitle = this.props.img.title || firstTag;

        return ( 
            <div 
                className={galleryStyles.imageItem} 
            >
                <img 
                    src={cloudinaryUrlFromLink(this.props.img.src, 768)} 
                    onMouseEnter={this.handleMouseEnter} 
                    onClick={this.props.openImgInLightbox}
                />

                {imgTitle && 
                    <Overlay 
                        onClick={this.props.openImgInLightbox}
                        onMouseLeave={this.handleMouseLeave} 
                    >
                        <div className={styles.tagsContainer}>
                            <p className={styles.tags}>{printTags(this.props.img.tags)}</p>
                        </div>
                    </Overlay>
                }
            </div>    
        );
    }
}

Image.propTypes = {
    img: PropTypes.object.isRequired,
    inFocus: PropTypes.bool.isRequired,
    permanentTag: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    focusImg: PropTypes.func.isRequired,
    blurImg: PropTypes.func.isRequired,
    openImgInLightbox: PropTypes.func.isRequired,
};

Image.defaultProps = {
  permanentTag: false,  
};